'use client'
import { type FC, memo } from 'react'
import { SectionDashboard } from '@/components/SectionDashboard'
import { MinifiedCalendar } from '@/components/MinifiedCalendar'
import { EProjectStatus, EProjectType } from '@/schema'

const mockDataTasks = [
  {
    id:1,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout'],
    duration: 2,
    description: 'Bocetos de pantalla y wireframes base',
    project: [
      {
        id:1,
        name: 'tempus2',
        client: {
          title: 'DY'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      }
    ],
    date: new Date('2024-03-19'),
  },
  {
    id:2,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'Research' ,
    task_tags: ['Layout'],
    duration: .5,
    description: 'Esta es 2 tarea',
    project:[
      {
        id:2,
        name: 'tempus2',
        client: {
          title: 'DY'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      },
    ],
    date: new Date ('2024-03-20'),
  },
  {
    id:3,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout'],
    duration: 2,
    description: 'Esta es 3 tarea',
    project:[
      {
        id:3,
        name: 'sablos',
        client: {
          title: 'Sablos'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      },
    ],
    date: new Date('2024-03-20'),
  },
  {
    id:4,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout'],
    duration: 2,
    description: 'Esta es 4 tarea',
    project:[
      {
        id:4,
        name: 'tempus2',
        client: {
          title: 'DY'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      },
    ],
    date: new Date('2024-03-20'),
  },
  {
    id:5,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout', 'briefing', 'test'],
    duration: 2,
    description: 'Esta es 5 tarea',
    project:[
      {
        id:5,
        name: 'sablos',
        client: {
          title: 'Sablos'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      },
    ],
    date: new Date('2024-03-20'),
  },
  {
    id:6,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout'],
    duration: 7,
    description: 'Esta es 6tarea',
    project: [
      {
        id:6,
        name: 'sablos',
        client: {
          title: 'Sablos'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      },
    ],
    date: new Date('2024-03-21'),
  },
  {
    id: 7,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout', 'briefing', 'test'],
    duration: 8,
    description: 'Esta es 7 tarea',
    project: [
      {
        id:7,
        name: 'tempus2',
        client: {
          title: 'DY'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      }
    ],
    date: new Date('2024-03-18'),
  },
  {
    id:6,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout', 'briefing', 'test'],
    duration: 2,
    description: 'Esta es 7 tarea',
    project: [
      {
        id:8,
        name: 'tempus2',
        client: {
          title: 'DY'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      }
    ],
    date: new Date('2024-09-28'),
  },
  {
    id:6,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout', 'briefing', 'test'],
    duration: 2,
    description: 'Esta es 7 tarea',
    project: [
      {
        id:9,
        name: 'blah',
        client: {
          title: 'DY'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      }
    ],
    date: new Date('2024-09-28'),
  },
  {
    id:6,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout', 'briefing', 'test'],
    duration: 1,
    description: 'Esta es 7 tarea',
    project: [
      {
        id:10,
        name: 'tempus2',
        client: {
          title: 'DY'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      }
    ],
    date: new Date('2024-09-28'),
  },
  {
    id:6,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout', 'briefing', 'test'],
    duration: 2,
    description: 'Esta es 7 tarea',
    project: [
      {
        id:11,
        name: 'tempus2',
        client: {
          title: 'DY'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      }
    ],
    date: new Date('2024-10-28'),
  },
  {
    id:6,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout', 'briefing', 'test'],
    duration: 2,
    description: 'Esta es 7 tarea',
    project: [
      {
        id:12,
        name: 'blah',
        client: {
          title: 'DY'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      }
    ],
    date: new Date('2024-10-28'),
  },
  {
    id:6,
    user: {
      name: 'Noemi',
      avatar: 'https://randomuser'
    },
    expertise: { name: 'UX' },
    task_type: 'creation' ,
    task_tags: ['Layout', 'briefing', 'test'],
    duration: 1,
    description: 'Esta es 7 tarea',
    project: [
      {
        id:13,
        name: 'tempus2',
        client: {
          title: 'DY'
        },
        type: EProjectType.INDIVIDUAL,
        status: EProjectStatus.ACTIVE,
        project_scope: '',
        start_date: new Date('2024-03-01'),
        end_date: new Date('2024-03-01'),
        bugs: 0,
        project_expertises: [{
          total: 20,
          current: 12,
          expertise: {
            name: 'UX'
          }
        }],
      }
    ],
    date: new Date('2024-10-28'),
  }
]

export const MainDashboard:FC = memo(() => {
  return (
    <main>
      <MinifiedCalendar type={'tasks'}/>
      <SectionDashboard data={mockDataTasks}/>
    </main>

  )
})
