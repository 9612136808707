'use client'
import React, { useMemo, type FC, useState } from 'react'
import styled from 'styled-components'
import { Card } from './Card'
import { useDates, useDeviceType, useMount } from '@/hooks'
import { formatDateToYYYYMMDD, groupByDayAndProject, groupByWeek, groupByDate }  from '@/utils'
import moment from 'moment'
import type { TypeCMSTask } from '@/schema'
import { mq, vw, font16_25, font24_27, font28_32, rgbAlpha, font16_20, font20_20, font11_13, font12_19 } from '@/styles'
import { Button } from './Button'
import { MODALS } from '@/context'
import { useModal } from '@/hooks'

type Props = {
  data: TypeCMSTask[]
}

function countDisplayDays(data, todayDay) {
  const dataKeys = Object.keys(data)
  let displayDaysCount = 0
  
  for (const key of dataKeys) {
    const date = moment(key, 'YYYY-MM-DD')
    const dayOfWeek = date.day()

    const weekendDisplayed = shouldDisplayWeekend(data, dayOfWeek, todayDay, key)
    // Count days including weekdays and weekends based on conditions
    if ((dayOfWeek >= 1 && dayOfWeek <= 5) || weekendDisplayed) {
      displayDaysCount++
    }
  }

  return displayDaysCount
}

function shouldDisplayWeekend(data, dayOfWeek: number, todayDay: number, key: string): boolean {
  const dataKeys = Object.keys(data)
  const lastSaturdayKey = dataKeys[dataKeys.length - 2]
  const lastSundayKey = dataKeys[dataKeys.length - 1]

  // Check if there's data on the last Saturday
  const hasDataOnLastSaturday = data[lastSaturdayKey] && data[lastSaturdayKey].length > 0

  // Display Saturday if today is Saturday and it's the current day
  if (dayOfWeek === 6 && (todayDay === 6 || hasDataOnLastSaturday)) return true
  // Display Sunday if today is Sunday and it's the current day, or if Saturday has data
  if (dayOfWeek === 0 && (todayDay === 0 || (hasDataOnLastSaturday && key === lastSundayKey))) return true

  return false
}

function checkWeek(data) {
  let isEmptyWeek = true

  for (const date in data) {
    if (data[date].length > 0) {
      isEmptyWeek = false
      break
    }
  }
  return isEmptyWeek
}

function checkDay(data, date) {
  if (!data.hasOwnProperty(date)) return true
  const dayTasks = data[date]
  for (const project in dayTasks) if (dayTasks[project].length > 0) return false

  return true
}

const groupTasksByProject = (tasks) => {
  return tasks.reduce((acc, task) => {
    const projectName = task.project[0].client.title
    if (!acc[projectName]) {
      acc[projectName] = []
    }
    acc[projectName].push(task)
    return acc
  }, {})
}

const getCardsWeek = (data: any, viewType: 'day' | 'week', setDuration, setDaysToDisplay, isMobile) => {
  const cards = []
  let duration = 0
  const today = moment()
  const todayDay = today.day() // Day of the week as a number (0 = Sunday, 6 = Saturday)

  const displayDaysCount = countDisplayDays(data, todayDay) // Count days before processing
  setDaysToDisplay(displayDaysCount)

  const isEmptyWeek = checkWeek(data)
  for (const key in data) {
    const date = moment(key, 'YYYY-MM-DD')
    const dayOfWeek = date.day()

    const weekendDisplayed = shouldDisplayWeekend(data, dayOfWeek, todayDay, key)
    // Skip weekends based on conditions
    if ((dayOfWeek === 6 || dayOfWeek === 0) && !weekendDisplayed) {
      continue
    }

    const tasks = data[key]
    const formattedDate = date.format('ddd D');
    const totalDuration = tasks.reduce((total, task) => total + task.duration, 0)
    duration += totalDuration

    // Group tasks by project for the day
    const tasksByProject = groupTasksByProject(tasks)

    cards.push(
      <dt key={`${key}-week`} className={`${weekendDisplayed && (dayOfWeek === 6 || dayOfWeek === 0) ? 'weekend' : ''} `}>
        <div key={key} className={`${(totalDuration < 4 && date.isBefore(today) && !isEmptyWeek) ? 'alert' : ''} ${isEmptyWeek ? 'empty' : ''}`}>
          <div>
            <p>{formattedDate}</p>
            <span>{totalDuration}h</span>
          </div>

          {(!isEmptyWeek && totalDuration < 4 && date.isBefore(today)) && isMobile && (
            <p>⚠️ Log your tasks!</p>
          )}
        </div>
        {!isMobile && tasks.map((item, idx) => {
          const maxDuration = 8
          const durationInHours = item.duration // duration of the task in hours
          const percentage = (durationInHours / maxDuration) * 100
          return (
            <DivStyled className='week_dd' key={`${key}-week-${idx}`} $heightBasedOnDuration={percentage}>
              <Card type={viewType} data={item} />
            </DivStyled>
          )
        })}
        {isMobile && Object.entries(tasksByProject).map(([projectName, projectTasks]: [string, TypeCMSTask[]]) => {
          return (
            <div className={'week_grouped'} key={projectName}>
              <h4>{projectName}</h4>
              {projectTasks.map((task, idx) => {
                const maxDuration = 8
                const percentage = (task.duration / maxDuration) * 100
                return (
                  <DivStyled className='week_dd' key={`${key}-week-${idx}`} $heightBasedOnDuration={percentage}>
                    <Card type={viewType} data={task} isGrouped={true} />
                  </DivStyled>
                )
              })}
            </div>
          )
        })}
        {(!isEmptyWeek && totalDuration < 4 && date.isBefore(today)) && !isMobile && (
          <LogYourTasks className={'log'}>
            <p>⚠️ Log your tasks!</p>
          </LogYourTasks>
        )}
      </dt>
    )
  }
  setDuration(duration)
  return cards
}

const calculateTotalTasksByDay = (data: { [key: string]: any[] }, today: string): number => {
  let totalTasksByDay = 0

  if (!data.hasOwnProperty(today)) return 0

  const dayTasks = data[today]
  for (const project in dayTasks) {
    if (dayTasks.hasOwnProperty(project)) {
      totalTasksByDay += dayTasks[project].length
    }
  }
  return totalTasksByDay
}

const getCardsByDay = (data, specificDay = null, setDuration, setIsEmptyDay, setTotalTasksByDay) => {
  const dataByDayAndProject = groupByDayAndProject(data)
  const today = formatDateToYYYYMMDD(specificDay ? specificDay : moment())
  const dataForToday: { [key: string]: any[] } = dataByDayAndProject[today] ?? {}
  const dataByDayAndProjectName = Object.entries(dataForToday).map(([projectName, tasks]: [string, any[]]) => ({
    projectName,
    clientName: tasks[0].project[0].client.title,
    tasks: tasks
    .sort((a: any, b: any) => a.duration - b.duration)
    .map((task: any) => ({ ...task }))
  }))

  const isEmptyDay = checkDay(dataByDayAndProject, today)
  setIsEmptyDay(isEmptyDay)
  setTotalTasksByDay(calculateTotalTasksByDay(dataByDayAndProject, today))

  const totalDuration =  Object.values(dataForToday).flat().reduce((total: number, item: any) => total + item.duration, 0)
  setDuration(totalDuration)
  
  if(dataByDayAndProjectName.length > 0) {
    return dataByDayAndProjectName.map((el, idx) => (
      <>
        <dd key={`${idx}-day`}>
          <Card type="day" data={el} />
        </dd>
        {(totalDuration <= 4) && <LogYourTasks className={'log'}><p>⚠️ Log your tasks!</p></LogYourTasks>}
      </>
    ))

  }
}

function groupByWeekAndDate(arr: any[], currentYear: number, currentWeek: number, setDuration:(number)=>void, setDaysToDisplay:(number)=>void, setIsEmptyWeek:(boolean)=>void, isMobile: boolean) {
  const dataByWeek = groupByWeek(arr)
  const dataForCurrentWeek = dataByWeek[currentWeek] || []
  const dataByDate = groupByDate(dataForCurrentWeek.map(item => ({
    ...item,
    date: moment(item.date).local().format('YYYY-MM-DD') // Convert UTC to local time zone
  })))

  // Get the start and end dates of the current week
  const startDate = moment().year(currentYear).week(currentWeek).startOf('week').local()
  const endDate = moment().year(currentYear).week(currentWeek).endOf('week').local()
  // Generate an object for each date in the current week
  const result = {}
  let date = moment(startDate)

  while (date.isBefore(endDate)) {
    const dateString = date.format('YYYY-MM-DD')
    result[dateString] = dataByDate[dateString] || []
    date = date.add(1, 'days')
  }

  const isEmptyWeek = checkWeek(result)
  setIsEmptyWeek(isEmptyWeek)
  return getCardsWeek(result, 'week', setDuration, setDaysToDisplay, isMobile)
}

export const SectionDashboard:FC<Props> = ({ data }) => {
  const { toggle } = useModal(MODALS.NEW_TASK)
  const { filterTaskType, currentDay, currentWeek, currentYear } = useDates()
  const isMount = useMount()
  const [daysToDisplay, setDaysToDisplay] = useState(5)
  const [durationWeek, setDurationWeek] = useState(0)
  const [durationDay, setDurationDay] = useState(0)
  const [isEmptyDay, setIsEmptyDay] = useState(true)
  const [isEmptyWeek, setIsEmptyWeek] = useState(true)
  const [totalTasksByDay, setTotalTasksByDay] = useState(1)
  const shouldDisplayTaskButtonCenterWindow = useMemo(() => (filterTaskType === 'day' && isEmptyDay) || (filterTaskType === 'week' && isEmptyWeek), [filterTaskType, isEmptyDay, isEmptyWeek])
  const { isMobile } = useDeviceType()
  const CardsDay = useMemo(() => getCardsByDay(data, currentDay, setDurationDay, setIsEmptyDay, setTotalTasksByDay), [data, currentDay, setDurationDay, setIsEmptyDay, setTotalTasksByDay])
  const CardsWeek = useMemo(() => groupByWeekAndDate(data, currentYear, currentWeek, setDurationWeek, setDaysToDisplay, setIsEmptyWeek, isMobile), [data, currentYear, currentWeek, setDurationWeek, setDaysToDisplay, setIsEmptyWeek, isMobile])

  return isMount && (
    <>
      <Section $viewType={filterTaskType} $daysToDisplay={daysToDisplay} $totalTasksByDay={totalTasksByDay}>
        <dl>
          {filterTaskType === 'day' ?
            <>
              {CardsDay}
            </>
            :
            <>
              {CardsWeek}
            </> 
          }
        </dl>
        {shouldDisplayTaskButtonCenterWindow && <Button className={'empty__week'} onClick={() => {toggle()}} size='big' bgType={'purple'}> Add new task</Button>}
        <Div className={ filterTaskType === 'week' && isEmptyWeek || filterTaskType === 'day' && isEmptyDay ? 'empty' : ''}>
          {filterTaskType === 'week' && 
            <p key='week'>
              {isEmptyWeek ? 
              <>🤟🏽 Have a nice week!</>
              :
              <>
                Week total <b>{durationWeek}</b>h  
                <span> (average <b> {(durationWeek/daysToDisplay).toFixed(2)}</b> h/day)</span>
              </>
              }
            </p>
          }
          {filterTaskType === 'day' &&
           <p key='day' className={durationDay < 4 ?'alert': ''}>{ isEmptyDay ? <>🤟🏽 Have a nice day!</> : <> Day total <b>{durationDay}</b>h</>}</p>
          }
          {!shouldDisplayTaskButtonCenterWindow && <Button onClick={() => { toggle() }}> Add new task</Button>}
        </Div>
      </Section>
    </>
  )
}

const Section = styled.section<any>`
  height: 100%;
 
  > dl {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    grid-template-rows: ${({ $totalTasksByDay }) => `repeat(${$totalTasksByDay}, max-content)`};
    gap: ${vw(8, 'mobile')};
    min-height: ${vw(480, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      gap: ${vw(8, 'tablet')};
      min-height: ${vw(480, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      grid-template-columns: ${({ $daysToDisplay, $viewType }) => $viewType === 'day' ? 'repeat(1,1fr)' : $daysToDisplay > 5 ? 'repeat(6, 1fr)': 'repeat(5, 1fr)'}; 
      grid-template-rows: ${({ $totalTasksByDay, $viewType }) => $viewType === 'day' ? `repeat(${$totalTasksByDay}, max-content)` : 'auto auto'};
      gap: ${vw(8, 'desktop')};
      min-height: ${vw(750, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      gap: 8px;
      min-height: 735px;
    }

    > dt {
      display: flex;
      flex-direction: column;
      gap: ${vw(8, 'mobile')};
      grid-row: unset;

      ${mq.greaterThan('nexus7')} {
        gap: ${vw(8, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        gap: ${vw(8, 'desktop')};
        grid-row: 1 / span 2;
      }

      ${mq.greaterThan('desktop')} {
        gap: 8px;
      }

      &.weekend {
        grid-column: 6 / span 1;
        grid-row: 1 / span 1;

        &:last-of-type{
          grid-row: 2 / span 1;
        }
      }
      > div {
        > div:not(.log, .week_dd, .week_grouped) {
          display: flex;
          flex-direction: row;
          gap: ${vw(10, 'mobile')};
          justify-content: flex-start;
          padding: 0 ${vw(10, 'mobile')};
          
          ${mq.greaterThan('nexus7')} {
            gap: ${vw(10, 'tablet')};
            padding: 0 ${vw(10, 'tablet')};
          }
  
          ${mq.greaterThan('tablet')} {
            gap: ${vw(10, 'desktop')};
            padding: 0 ${vw(10, 'desktop')};
          }
  
          ${mq.greaterThan('desktop')} {
            gap: 10px;
            padding:0 10px;
          }
  
          p {
            ${font12_19(true, 400)}
  
            ${mq.greaterThan('tablet')} {
              ${font16_25(false, 400)}
            }
          }
  
          > span {
            ${font12_19(true, 600)}
  
            ${mq.greaterThan('tablet')} {
              ${font16_25(false, 600)}
            }
          }
  
        }
        
      }
      
      .alert {
        color: var(--color-light_orange-alert);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > p {
          ${font12_19(true, 400)}

          ${mq.greaterThan('tablet')} {
            ${font16_25(false, 400)}
          }
        }
      }

      .empty {
        opacity: 0.3;
      }
      .week_grouped {
        background-color: ${rgbAlpha('--color-white', .05)};
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        > h4 {
          ${font11_13(true, 400)}
          text-align: left;
          margin-bottom: 5px;

          ${mq.greaterThan('tablet')} {
            ${font11_13(false, 400)}

          }
        }
        .week_dd {
          height: 100%;
          min-height: unset;
        }
      }
    }
  }
  .empty__week {
    left: 50%;
    position: absolute;
    top: 95%;
    transform: translate(-50%,-50%);
    width: 90%;
    z-index: 1;

    ${mq.greaterThan('tablet')} {
      top: 50%;
      width: 200px;
    }

  }
`

const DivStyled = styled.div<{ $heightBasedOnDuration: number }>`
  height: ${({ $heightBasedOnDuration }) => $heightBasedOnDuration}%;
  min-height: 9%;
`

const LogYourTasks = styled.div`
  align-items: center;
  align-self: stretch;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  border: 0px solid var(--color-light_orange);
  display: flex;
  gap: 5px;
  height: 60px;
  justify-content: center;
  padding: 10px;

  > p {
    ${font16_25(true, 400)}
    color: var(--color-light_orange);

    ${mq.greaterThan('tablet')} {
      ${font16_25(false, 400)}
    }
  }
`

const Div = styled.div`
  align-items: center;
  bottom: 0;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 50%;
  margin: ${vw(10, 'mobile')} auto 0;
  padding: 10px 0;
  position: fixed;
  transform: translateX(-50%);
  width: 100%;
  z-index: 2;

  ${mq.greaterThan('nexus7')} {
    margin: ${vw(10, 'tablet')} auto 0;
  }

  ${mq.greaterThan('tablet')} {
    align-items: unset;
    background-color: transparent;
    bottom: unset;
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
    margin: ${vw(10, 'desktop')} 0 0;
    padding: unset;
    transform: unset;
    position: unset;
    width: 100%;
  }

  ${mq.greaterThan('desktop')} {
    margin: 10px 0 0;
  }

  &.empty {
    align-items: center;
    bottom: 80px;
    display: flex;
    justify-content: center;

    ${mq.greaterThan('tablet')} {
      justify-content: flex-start;
    }
  }

  > button {
    margin: 0;
    width: 90%;

    ${mq.greaterThan('tablet')} {
      width: unset;
    }
  }

  > p {
    ${font24_27(true, 600)}

    ${mq.greaterThan('tablet')} {
      ${font24_27(false, 600)}
    }

    > b {
      ${font28_32(true, 600)}

      ${mq.greaterThan('tablet')} {
        ${font28_32(false, 600)}
      }
    }

    > span {
      ${font16_20(true, 400)}
      color: ${rgbAlpha('--color-white', .3)};
      ${mq.greaterThan('tablet')} {
        ${font16_20(false, 400)}
      }
      
      > b {
        ${font20_20(true, 400)}
        color: ${rgbAlpha('--color-white', .3)};
        
        ${mq.greaterThan('tablet')} {
          ${font20_20(false, 400)}
        }
      }
    }
    
    &.alert {
      color: var(--color-light_orange);
    }
  }
`